import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CodeData, StaffMember } from 'src/app/interface/interface';
import { send } from 'process';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
    public API: any = {};
    private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    constructor(
        private  http: HttpClient
    ) {
        // this.API.baseUrl = 'http://localhost/iholo.co.za/admin.iholo.co.za'; // LOCAL
        // this.API.baseUrl = 'https://dev.localhost.co.za:8081'; // LOCAL
        this.API.baseUrl = 'https://admin.iholo.co.za'; // LIVE
        this.API.getBranch = '/api/getbranch';
        this.API.rating = '/api/rating';
        this.API.getBarcodeInfo = '/api/getbarcodeinfo';
        this.API.getThankyouData = '/api/getthankyoudata';
        this.API.savePoints = '/api/savepoints';
        this.API.getOwzowHash = '/api/getozowhash';
        this.API.updatePaymentInfo = '/api/updatepaymentinfo';
        this.API.getRateInfo = '/api/getrateinfo';
        this.API.triggerGoogle = '/api/triggergoogle';
    }
    transformRequest(obj: any) {
        const str = [];
        for (const p in obj) {
            if (typeof p !== undefined) {
                str.push(
                    encodeURIComponent(p) + '=' + encodeURIComponent(obj[p])
                );
            }
        }
        return str.join('&');
    }
    httpPost(url: string, sendData: object) {
        return this.http.post(
            url,
            this.transformRequest(sendData),
            {headers: this.headers}
        );
    }
    httpGet(url: string, sendData: object) {
        return this.http.get(
            url + '?' + this.transformRequest(sendData),
            {headers: this.headers}
        );
    }
    getBranch(code: CodeData) {
        const sendData = {
            barcode: code.code,
            type: 'branch'
        };
        return this.httpPost(this.API.baseUrl + this.API.getBranch, sendData);
    }
    saveRating(code: string, staff: StaffMember, tip: any) {
        const sendData = {
            rate: staff.rate,
            staff_id: staff.id,
            comment: staff.comment,
            payment: tip,
            key: code
        };
        return this.httpPost(this.API.baseUrl + this.API.rating, sendData);
    }
    getBarcodeInfo(code: string) {
        const sendData = {
            barcode: code
        };
        return this.httpPost(this.API.baseUrl + this.API.getBarcodeInfo, sendData);
    }
    getRateInfo(code: string) {
        const sendData = {
            key: code
        };
        return this.httpPost(this.API.baseUrl + this.API.getRateInfo, sendData);
    }
    getThankyouData(code: string) {
        const sendData = {
            key: code
        };
        return this.httpPost(this.API.baseUrl + this.API.getThankyouData, sendData);
    }
    savePoints(pointsInput: string, code: string) {
        const sendData = {
            points: pointsInput,
            key: code
        };
        return this.httpPost(this.API.baseUrl + this.API.savePoints, sendData);
    }
    getOzowHash() {
        const sendData = {

        };
        return this.httpPost(this.API.baseUrl + this.API.getOwzowHash, sendData);
    }
    updatePaymentInfo(trigger: string, key: any, cardData: StaffMember) {
        const sendData = {
            vendor: trigger,
            reference: key,
            amount: cardData.amount
        };
        return this.httpPost(this.API.baseUrl + this.API.updatePaymentInfo, sendData);
    }
    triggerGoogle(code: string) {
        const sendData = {
            key: code
        };
        return this.httpPost(this.API.baseUrl + this.API.triggerGoogle, sendData);
    }
}
