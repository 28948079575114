import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'scan',
        pathMatch: 'full'
    }, {
        path: 'scan/:branch',
        loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
    }, {
        path: 'scan/:branch/:staff',
        loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
    }, {
        path: 'scan',
        loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
    }, {
        path: 'code/:code',
        loadChildren: () => import('./pages/code/code.module').then( m => m.CodePageModule)
    }, {
        path: 'code',
        loadChildren: () => import('./pages/code/code.module').then( m => m.CodePageModule)
    }, {
        path: 'thankyou/:key',
        loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule)
    }, {
        path: 'thankyou',
        loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule)
    }, {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
    }, {
        path: 'scan/:code',
        loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
    }, {
        path: 'rate',
        loadChildren: () => import('./pages/rate/rate.module').then( m => m.RatePageModule)
    }, {
        path: 'rate/:key',
        loadChildren: () => import('./pages/rate/rate.module').then( m => m.RatePageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
